<template>
  <div class="mx-auto">
    <ItemDetailPost :loading="loading" :post="getPost" />

    <div v-if="!loading" class="mt-8 text-center">
      <v-btn class="label--text darken-1 label-button-list text-none" text tile small plain :ripple="false" to="/post"
        @click="$router.go(-1)">
        <v-icon left size="12">$play</v-icon>
        {{ $t("notice_list") }}
      </v-btn>
    </div>
  </div>
</template>

<script>
import ItemDetailPost from "@/components/ui/ItemDetailPost";
import dayjs from "@/plugins/dayjs";
import { mapGetters } from "vuex";
export default {
  name: "PostDetail",
  // eslint-disable-next-line vue/no-unused-components
  components: { ItemDetailPost },
  computed: {
    ...mapGetters(["getPost"])
  },
  data() {
    return {
      loading: false,
      date: dayjs().format("YYYY/MM/DD")
    };
  },
  created() {
    this.getDataFromApi();
  },
  methods: {
    getDataFromApi() {
      this.loading = true;
      this.$store
        .dispatch("POST_GET_DETAIL", {
          id: this.$route.params.id
        }).then(
          response => {
            if (response.data.data.length == 0) {
              this.$router.push({ name: 'post' })
            }
          },
          error => {
            throw error
          }
        )
        .finally(() => {
          this.loading = false;
        });
        
    }
  }
};
</script>

<style scoped>
</style>
