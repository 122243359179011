<template>
  <PostList />
</template>

<script>
import PostList from "@/components/layout/posts/PostList";
export default {
  name: "Posts",
  components: { PostList }
};
</script>

<style scoped></style>
