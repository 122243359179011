<template>
  <div class="mx-auto">
    <v-row>
      <v-col cols="12">
        <v-row class="ma-0 bg-blk">
          <v-col cols="6" sm="6">
            <v-select
              :items="yearsList()"
              v-model="sortBy.year"
              solo
              dense
              hide-details
            >
            </v-select>
          </v-col>
          <v-col cols="6" sm="6">
            <v-select
              :items="monthList()"
              v-model="sortBy.month"
              solo
              dense
              hide-details
            ></v-select>
          </v-col>
        </v-row>
      </v-col>

      <v-col cols="12" class="px-0">
        <v-data-table
          :items="getPosts"
          :hide-default-footer="true"
          hide-default-header
          disable-filtering
          disable-sort
          mobile-breakpoint="0"
          :items-per-page="itemsPerPage"
          :server-items-length="itemsPerPage"
          @update:options="updateTable"
          no-data-text="お知らせがありません"
        >
          <template v-slot:item="{ item, index }">
            <ListItemPost
              class="mx-3"
              :post="item"
              :class="
                index === 0
                  ? 'mb-4'
                  : index === getPosts.length - 1
                  ? 'mt-4 mb-2'
                  : 'my-4'
              "
              :read-status="item.is_read !== 0"
            />
          </template>
        </v-data-table>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import ListItemPost from "@/components/ui/ListItemPost";
import { mapGetters } from "vuex";
export default {
  name: "PostList",
  components: { ListItemPost },
  data() {
    return {
      loading: false,
      itemsPerPage: 10,
      sortBy: {
        year: this.getCurrentYear(),
        month: this.getCurrentMonth()
      }
    };
  },
  created() {
    // this.getDataFromApi();
  },
  computed: {
    ...mapGetters([
      "getPosts",
      "getPostsPagination",
      "getPostListTitle",
      "user"
    ]),
    postTitle() {
      if (
        this.$route.params.category_id == undefined &&
        this.$route.params.tag_id == undefined
      ) {
        return this.$t("posts");
      } else {
        return this.getPostListTitle;
      }
    },
    postSubTitle() {
      if (
        this.$route.params.category_id == undefined &&
        this.$route.params.tag_id == undefined
      ) {
        return this.$t("posts_subtitle");
      } else {
        return this.$t("posts_subtitle_related");
      }
    },
    footers() {
      return {
        itemsPerPageText: this.$t("items_per_page"),
        itemsPerPageOptions: [this.itemsPerPage],
        pagination: {
          page: 1,
          itemsPerPage: this.itemsPerPage,
          pageStart: this.getPostsPagination.records_from - 1,
          pageStop: this.getPostsPagination.records_to,
          pageCount: this.getPostsPagination.total_pages,
          itemsLength: this.getPostsPagination.records_total
        },
        showCurrentPage: true
      };
    }
  },
  methods: {
    updateTable(e) {
      this.getDataFromApi(e.page);
    },
    getDataFromApi(page, filter) {
      this.loading = true;

      let params = {
        paginate: this.itemsPerPage,
        page: page,
        sort: "post_date",
        filter: filter,
        order: "desc"
      };

      if (this.$route.params.category_id != undefined) {
        params.category_id = this.$route.params.category_id;
      }

      if (this.$route.params.tag_id != undefined) {
        params.tag_id = this.$route.params.tag_id;
      }

      this.$store
        .dispatch("POST_GET", {
          params: params
        })
        .finally(() => {
          this.loading = false;
        });
    },
    getCurrentYear() {
      return new Date().getFullYear();
    },
    getCurrentMonth() {
      return new Date().getMonth() + 1;
    },
    yearsList() {
      let year = [];
      let date = this.user.created_at ?? "2021-01-01";
      let current = new Date(date).getFullYear();
      let end = new Date().getFullYear();
      for (let i = current; i <= end; i++) {
        if (i < 10) i = `0${i}`;
        year.push({ value: i, text: `${i}年` });
      }
      return year;
    },
    monthList() {
      let month = [{ value: 'all', text: `全て` }];
      for (let i = 1; i <= 12; i++) {
        // if (i < 10) i = `0${i}`;
        month.push({ value: i, text: `${i}月` });
      }
      return month;
    }
  },
  watch: {
    sortBy: {
      handler(val) {
        let date = "";
        if(val.month != "all"){
          date = val.year + "-" + val.month + "-1";
        }
        this.getDataFromApi(null, date);
      },
      deep: true
    }
  }
};
</script>

<style scoped>
.theme--light.v-data-table {
  background-color: transparent;
}

.theme--light.v-data-table >>> .v-data-footer {
  border: none !important;
}

.bg-blk {
  background: #f9f9f9;
  border: 1px solid #bdbdbd;
  border-radius: 5px;
}
.bg-blk >>> .v-input__slot {
  box-shadow: none !important;
  border: 1px solid #e0e0e0;
  border-radius: 5px;
}
</style>
