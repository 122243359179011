<template>
  <v-card :loading="loading" flat class="rounded-0">
    <v-skeleton-loader v-if="loading" type="card-heading" :loading="loading" />
    <v-card-text v-if="!loading">
      <v-row>
        <v-col cols="12" class="pb-0">
          <span class="font-weight-regular text-heading-1 main--text grey-1">
            {{ formatDate(post.post_date) }}
          </span>
          <span class="font-weight-regular main--text grey-1 pl-2">
            ({{ getDay(post.post_date) }})
          </span>
        </v-col>
        <v-col cols="12" class="pb-0">
          <span class="font-weight-bold main--text lighten-1 fs-16">
            {{ post.title }}
          </span>
        </v-col>
      </v-row>
      <v-divider class="my-5"></v-divider>
      <v-row>
        <v-col cols="12" ref="content">
          <span class="content-wrap">{{ post.content }}</span>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
import dayjs from "@/plugins/dayjs";
export default {
  name: "ItemDetailPost",
  props: {
    loading: {
      type: Boolean,
      default: false,
      required: false
    },
    post: {
      type: Object,
      default: () => {},
      required: true
    }
  },
  computed: {
    labelClasses() {
      if (!this.post.type) {
        return "";
      }
      return this.post.type.id.toLowerCase() === "important"
        ? "white--text item-post-label-text item-post-label-important text-uppercase"
        : "grey--text text--darken-2 item-post-label-text font-weight-medium item-post-label-regular text-uppercase";
    }
  },
  methods: {
    formatDate(date) {
      return dayjs(date).format("YYYY年 M月 D日");
    },
    getDay(date) {
      var days = [
        "日", //Sun
        "月", //Mon
        "火", //Tue
        "水", //Wed
        "木", //Thu
        "金", //Fri
        "土" //Sat
      ];
      var d = new Date(date);
      var dayName = days[d.getDay()];
      return dayName;
    }
  }
};
</script>

<style scoped>
.fs-16 {
  font-size: 16px;
}
.item-post-label-text.v-chip.v-size--small {
  font-size: 14px;
}

.item-post-label-important {
  background: #c63d34 !important;
  border-color: #c63d34 !important;
}

.item-post-label-regular {
  background: transparent !important;
  border-radius: 0 !important;
  border: 0;
  border-bottom: #c2c2c2 solid 1px;
  padding-left: 0;
  padding-right: 0;
}

.item-post-unread--text {
  position: absolute;
  vertical-align: middle;
  color: #f03636;
}

.item-post-unread {
  background-color: #ffe1e1 !important;
}

.item-post-label-date {
  font-size: 12px;
}

.item-post-content-truncate {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
.item-detail-post-links {
  color: #256cf8;
}

::v-deep img {
  width: 100%;
  height: auto;
}

::v-deep .media {
  width: 100%;
  padding-top: 8px;
  height: 210px;
}

::v-deep .youtube {
  width: 100%;
  height: 100%;
}
.grey-1 {
  color: #828282 !important;
}
.content-wrap {
  white-space: pre-wrap;
  word-wrap: break-word;
  font-family: inherit;
  color: #000;
  font-size: 14px;
}
</style>
