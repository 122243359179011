<template>
  <v-card class="px-3 py-5 listing-blk" outlined @click="goToDetail">
    <v-container>
      <v-row align="center" justify="center">
        <v-col cols="11">
          <v-row>
            <v-col cols="12" class="py-0 px-0">
              <v-row>
                <div v-if="!post.pivot.is_read">
                  <v-icon color="#EB5757" size="6">$dot</v-icon>
                </div>
                <div class="ml-2">
                  <span class="font-weight-regular main--text grey-1">
                    {{ formatDate(post.post_date) }}
                  </span>
                  <span class="font-weight-regular main--text grey-1 pl-2">
                    ({{ getDay(post.post_date) }})
                  </span>
                  <div>
                    <span
                      class="main--text item-post-content-truncate text-justify"
                      v-html="post.title"
                    ></span>
                  </div>
                </div>
              </v-row>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-container>
  </v-card>
</template>

<script>
import dayjs from "@/plugins/dayjs";
export default {
  name: "ListItemPost",
  props: {
    post: {
      type: Object,
      default: () => {},
      required: true
    },
    readStatus: {
      type: Boolean,
      default: false,
      required: false
    },
    isDisplayedOnHomePage: {
      type: Boolean,
      default: false,
      required: false
    }
  },
  computed: {
    labelClasses: function() {
      if (!this.post.type) {
        return "";
      }
      return this.post.type.id.toLowerCase() === "important"
        ? "white--text item-post-label-text item-post-label-important text-uppercase"
        : "grey--text text--darken-2 item-post-label-text font-weight-medium item-post-label-regular text-uppercase";
    },
    cardColor: function() {
      return !this.readStatus && this.isDisplayedOnHomePage
        ? "item-post-unread"
        : "";
    }
  },
  methods: {
    goToDetail() {
      return this.$router.push({
        name: "post_detail",
        params: { id: this.post.id }
      });
    },
    getDay(date) {
      var days = [
        "日", //Sun
        "月", //Mon
        "火", //Tue
        "水", //Wed
        "木", //Thu
        "金", //Fri
        "土" //Sat
      ];
      var d = new Date(date);
      var dayName = days[d.getDay()];
      return dayName;
    },
    formatDate(date) {
      return dayjs(date).format("YYYY年 M月 D日");
    }
  }
};
</script>

<style scoped>
.item-post-label-text.v-chip.v-size--small {
  font-size: 14px;
}

.item-post-label-important {
  background: #c63d34 !important;
  border-color: #c63d34 !important;
}

.item-post-label-regular {
  background: transparent !important;
  border-radius: 0 !important;
  border: 0;
  border-bottom: #c2c2c2 solid 1px;
  padding-left: 0;
  padding-right: 0;
}

.item-post-unread--text {
  position: absolute;
  vertical-align: middle;
  color: #f03636;
}

.item-post-unread {
  background-color: #ffe1e1 !important;
}

.item-post-label-date {
  font-size: 12px;
}

.item-post-content-truncate {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
.listing-blk {
  border: 1px solid #e0e0e0 !important;
  font-size: 14px !important;
  line-height: 2;
}
.grey-1 {
  color: #828282 !important;
}
</style>
